import React, { FC, useContext } from "react";
import { AspectRatio, Box, Flex, Heading } from "@chakra-ui/react";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { GenericHeader } from "~/foundation/Components/GenericHeader";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { MultiColumnPromoProps } from "../generated-types";
import { ExtendedBox } from "~/foundation/Components/ExtendedBox";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { imageSizes, themable } from "~/foundation/Theme";

enum Orientation {
	LargeImageLeft = "1"
}

const MultiColumnPromo: FC<MultiColumnPromoProps> = ({ fields, rendering, params }) => {
	if (!fields) {
		return <></>;
	}

	const { sitecoreContext } = useSitecoreContext();
	const [theme] = useContext(ColorThemeContext);
	const largeImageFirst = params.orientation === Orientation.LargeImageLeft;

	return (
		<Ribbon py={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}>
			<ContentWrapper py={["0", null]}>
				<GenericHeader {...fields}/>
			</ContentWrapper>
			<ContentWrapper py="0">
				<Box>
					<Flex flexDirection={largeImageFirst ? ["column", null, "row"] : ["column-reverse", null, "row-reverse"]} gap={["8", null, "5"]}>
						<Flex flexDirection="column" width={["100%", null, "67.3%"]}>
							{(fields?.largeImage?.value?.src || sitecoreContext.pageEditing) &&
								<Box position="relative" flexGrow="1">
									<sc.AdvanceImage
										field={fields.largeImage}
										fieldNames={["Large Image"]}
										itemId={rendering.dataSource}
										sizes="(min-width: 1760px) 904px, (min-width: 1440px) 783px, (min-width: 768px) 482px, 100vw"
										srcSet={[imageSizes.promoList.square.sm, imageSizes.promoList.square.md, imageSizes.promoList.square.lg]}
										sx={{
											position: ["relative", null, "absolute"],
											objectFit: ["contain", null, "cover"],
											objectPosition: "center center",
											minWidth: "100%",
											minHeight: "100%",
											w: "100%",
											h: "100%",
											borderRadius: "lg",
											borderBottomRightRadius: "0"
										}}
									/>
								</Box>
							}
							{(fields?.largeTitle?.value || sitecoreContext.pageEditing) &&
								<Heading as="h3" size="xs" mt={["6", null, "4"]}>
									<sc.Text field={fields.largeTitle}/>
								</Heading>
							}
							{(fields?.largeCallToAction?.value.href || sitecoreContext.pageEditing) &&
								<ExtendedBox mt={["2", null, "4"]}>
									<sc.Link field={fields?.largeCallToAction} variant="secondary" size="md" color={theme.colors.ribbon.linkColor} />
								</ExtendedBox>
							}
						</Flex>
						<Flex flexDirection="column" width={["100%", null, "32.7%"]} gap={["8", null, "8"]}>
							<Box>
								{(fields?.smallImageTop?.value?.src || sitecoreContext.pageEditing) &&
									<AspectRatio ratio={4 / 3}>
										<sc.AdvanceImage
											field={fields.smallImageTop}
											fieldNames={["Small Image Top"]}
											itemId={rendering.dataSource}
											sizes="(min-width: 1440px) 592px, (min-width: 768px) 380px, 100vw"
											srcSet={[imageSizes.promoList.landscape.sm, imageSizes.promoList.landscape.md, imageSizes.promoList.landscape.lg]}
											sx={{ maxWidth: "100%", minWidth: "100%", borderRadius: "lg", borderBottomRightRadius: "0" }}/>
									</AspectRatio>
								}
								{(fields?.smallTitleTop?.value || sitecoreContext.pageEditing) &&
									<Heading as="h3" size="xs" mt={["6", null, "4"]}>
										<sc.Text field={fields.smallTitleTop}/>
									</Heading>
								}
								{(fields?.smallCallToActionTop?.value.href || sitecoreContext.pageEditing) &&
									<ExtendedBox mt={["2", null, "4"]}>
										<sc.Link field={fields?.smallCallToActionTop} variant="secondary" size="md" color={theme.colors.ribbon.linkColor} />
									</ExtendedBox>
								}
							</Box>
							<Box>
								{(fields?.smallImageBottom?.value?.src || sitecoreContext.pageEditing) &&
									<AspectRatio ratio={4 / 3}>
										<sc.AdvanceImage
											field={fields.smallImageBottom}
											fieldNames={["Small Image Bottom"]}
											itemId={rendering.dataSource}
											sizes="(min-width: 1440px) 592px, (min-width: 768px) 380px, 100vw"
											srcSet={[imageSizes.promoList.landscape.sm, imageSizes.promoList.landscape.md, imageSizes.promoList.landscape.lg]}
											sx={{ maxWidth: "100%", minWidth: "100%", borderRadius: "lg", borderBottomRightRadius: "0" }}/>
									</AspectRatio>
								}
								{(fields?.smallTitleBottom?.value || sitecoreContext.pageEditing) &&
									<Heading as="h3" size="xs" mt={["6", null, "4"]}>
										<sc.Text field={fields.smallTitleBottom}/>
									</Heading>
								}
								{(fields?.smallCallToActionBottom?.value.href || sitecoreContext.pageEditing) &&
									<ExtendedBox mt={["2", null, "4"]}>
										<sc.Link field={fields?.smallCallToActionBottom} variant="secondary" size="md" color={theme.colors.ribbon.linkColor} />
									</ExtendedBox>
								}
							</Box>
						</Flex>
					</Flex>
				</Box>
			</ContentWrapper>
		</Ribbon>
	);
};

export default themable()(MultiColumnPromo);